import { Component } from "react";
import { withRouter } from "react-router-dom";

import store from "features/store";

const allowedPaths = ['/messages/unread', '/messages/read', '/messages/replied', '/messages/deleted'];

class RouteChangeListener extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (!allowedPaths.includes(this.props.location.pathname)) {
                store.dispatch({ type: 'SET_MESSAGE_ITEMID', payload: null });
            }
        }
    }

    render() {
        return null;
    }
}

export default withRouter(RouteChangeListener);