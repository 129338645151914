import { Component, createRef } from 'react';
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { mysteryGiftList, locationlist } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';
import * as momentTz from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import './Admin.scss';


export default class MysteryRewardReport extends Component {
    initialSearchState;
    search;
    startDatePicker;
    endDatePicker;
    config;
    id;
    filterSearch;
    constructor(props) {
        super(props);

        this.dataTable = createRef();

        this.initialSearchState = {
            startdate: {  field: "optedindate", value: "",op: ">=", join: "AND"},
            enddate: { field: "optedindate", value: "",op: "<=", join: "AND" },
        }
        this.state = {
            search: this.initialSearchState,
            searchData: false,
            modal: false,
            tableData: [],
            salesDetails: {},
            category: [],
            sales: [],
            issearch: false,
            saleid: { value: '', valid: false, error: '' },
            locations: [],
            isdisabled: false,
            location: '',
            locationlength:0,
            isActiveCheckbox : "",
            isDateFromCheckBox: "",
            DateCheckBoXValue: '',
            currentDate : '',
            SearchButton : false,
            locationAllSelected: false
        }

        this.config = {
            name: 'mysterReward',
            url: mysteryGiftList,
            isReport: true,
            params: {},
            columns: [
                { name: 'First Name', property: 'firstname', width: '150px' },
                { name: 'Last Name', property: 'lastname', width: '150px' },
                { name: 'Email', property: 'email', width: '150px' },
                { name: 'Entered to win date', property: 'optedindate', sort: true, width: '170px', columnType: 'custom', customColumn: this.customEndDate },
                {
                    name: '', property: 'id', columnType: 'actions', actions: [], customHeader: "CSV", width: '170px',
                }
            ],
            sort: [{"field": "optedindate", "direction": "desc" }],
            field: ['firstname', 'lastname', 'email','optedindate',"buyerid"],
       
            csvColumn: [
                
            ],
            filename: "Mystery-Reward-Report",
            pagination: true,
            ignoreRowBackground: true
          
        };

        this.search = {};
        this.startDatePicker = false;
        this.endDatePicker = false;
        this.id = null;
        this.locations = [];

    }
    

    componentDidMount = () => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBack);
    }

    onBack = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
    }
    customEndDate = (value) => {
        if (value['optedindate']) {
            return `${moment.utc(value['optedindate']).format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };

    resetState = () => {
        this.setState({
            ...this.state,
            search: this.initialSearchState,
            searchData: false,
            issearch: false,
            isdisabled: false,
            isActiveCheckbox: '',
            isDateFromCheckBox: false,
            DateCheckBoXValue: '',
            currentDate: '',
            locationAllSelected: false,
            SearchButton: false
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
        }
    }

    enableSearch = () => {

        let startDate = null
        let endDate =  null

        if(this.state.search.startdate.value){
            let startDateFormat = moment(this.state.search.startdate.value).format("YYYY-MM-DD")
            startDate =  moment.tz(startDateFormat + ' 00:00:00', "US/Eastern").utc().format("YYYY-MM-DD hh:mm:ss")
        }
        
        if( this.state.search.enddate.value){
            let endDateformat = moment( this.state.search.enddate.value).format("YYYY-MM-DD")
            endDate =  moment.tz(endDateformat + ' 23:59:59', "US/Eastern").utc().format("YYYY-MM-DD hh:mm:ss")
        }
        var search = {
            startdate: { ...this.state.search.startdate, value: this.state.search.startdate.value ? startDate : '' },
            enddate: { ...this.state.search.enddate, value:  this.state.search.enddate.value ? endDate : '' },

        }
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }


    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event
        });
    }

    setDate = (control, date) => {
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [control]: {
                    ...this.state.search[control],
                    value: date
                },
            }
        },() => {
            this.setState({
                ...this.state,
                isActiveCheckbox: '',
                isDateFromCheckBox: false,
                DateCheckBoXValue: '',
                currentDate: ""
            },()=>{
            })
        });

        }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }



    render() {
        return (
            <div style={{ overflow: "auto", overflowY: "overlay" }} id="sale-hostory-report">
                <div className="sale-search">
                    <div className="sales-list-search">
                        <Form className="sales-list-font">
                            <div className="sales-search-container">
                                <Row>
                              
                                    <Col>
                                        <label>Start Date</label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.start.state.open && e.preventDefault()}>
                                                <DatePicker ref={r => this.start = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.startdate.value} selected={this.state.search.startdate.value} onChange={date => { this.setDate('startdate', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#00263E" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col>
                                    
                                    <Col>
                                        <label>End Date </label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.end.state.open && e.preventDefault()}>
                                                <DatePicker minDate={this.state.search.startdate.value ? this.state.search.startdate.value : ''} ref={r => this.end = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.enddate.value} selected={this.state.search.enddate.value} onChange={date => { this.setDate('enddate', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#00263E" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col> 
                                    <Col>
                                    </Col>
                             
                                    <Col>
                                        <div className="mt-3">
                                            <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                            <Button variant="seller-search"  onClick={() => this.enableSearch()}>Search</Button>
                                        </div>
                                    </Col>                      
                                </Row>
                            </div>
                        </Form>

                    </div>
                </div>
                <div className="sale-list">
                    <Datatable config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} onDataUpdate={this.updateTableData}></Datatable>
                </div>
            </div>
        );
    }
}